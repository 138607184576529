import styled from "@emotion/styled";


type SlipProps = {
	backgroundColor?: string;
};
const SlipContainer = styled.div<SlipProps>`
	display: flex;
	flex-direction: column;
	gap: 1em;
	padding: 2em 
	1.5em;
	text-align: left;
	
	max-width: calc(var(--root-view-width) * 0.95);
	//max-height: 80%;
	//overflow: scroll;
	
	${({backgroundColor}) => backgroundColor && `background-color: ${backgroundColor};`}

  --mask:
  radial-gradient(22.36px at 50% 30px,#000 99%,#0000 101%) calc(50% - 20px) 0/40px 51% repeat-x,
  radial-gradient(22.36px at 50% -20px,#0000 99%,#000 101%) 50% 10px/40px calc(51% - 10px) repeat-x,
  radial-gradient(22.36px at 50% calc(100% - 30px),#000 99%,#0000 101%) 50% 100%/40px 51% repeat-x,
  radial-gradient(22.36px at 50% calc(100% + 20px),#0000 99%,#000 101%) calc(50% - 20px) calc(100% - 10px)/40px calc(51% - 10px) repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
`

const SlipHeader = styled.div`
	display: flex;
  justify-content: space-between;
  align-items: center;
  //padding: 1em;
  font-size: 1.2em;
  color: #333;
  text-transform: uppercase;
	> h1 {
		font-size: 2.6em;
		font-weight: 600;
		line-height: 1em;
    white-space: break-spaces;
	}
  > img {
    width: 25%;
	  //height: auto;
	  aspect-ratio: 1;
  }
`

const VisitorDetails = styled.div`
  text-transform: uppercase;
	line-height: 2;
`

const SignatureContainer = styled.div`
	font-size: 0.9em;
	line-height: 2;
	> span {
		margin-top: 0.2em;
	}
	> img {
    mix-blend-mode: multiply;
    filter: contrast(1.5);
    border-radius: 6px;
  }
`

const VisitorTitle = styled.div`
	text-transform: uppercase;
	text-align: center;
	font-size: 1.6em;
	font-weight: 500;
`


const TermsConditions = styled.div`
	font-size: 0.8em;
	line-height: 1.4;
	> strong {
		line-height: 1.5;
	}
`

const parseExpiryDate = (dateTimeMs?: string, short: boolean = false) => {
	if (!dateTimeMs) return '';
	if (isNaN(parseInt(dateTimeMs))) return dateTimeMs;

	const date = new Date(parseInt(dateTimeMs));
	if (short) {
		//formatted 29-10-24 TUE
		const datePart = date.toLocaleDateString('en-AU', {
			day: '2-digit',
			month: '2-digit',
			year: '2-digit'
		}).replace(/\//g, '-');

		const weekday = date.toLocaleDateString('en-AU', { weekday: 'short' }).toUpperCase();

		return `${datePart}\n${weekday}`;
	}
	//formatted 29/10/24 10:42:31 AM
	return `${date.toLocaleDateString('en-AU', { year: '2-digit', month: '2-digit', day: 'numeric' })} ${date.toLocaleTimeString('en-AU', {})}`;
}

export type VisitorCardProps = {
	backgroundImage: string,
	backgroundColor: string,

	visitorId: number,
	fullName: string,
	selfieImgUrl: string,
	visitorIssuedTime: string,
	// cardQrCode: string,
	logoImgUrl: string,
	signatureImgUrl?: string,
}

const VisitorSlip = ({backgroundColor, logoImgUrl, visitorId, fullName, visitorIssuedTime, signatureImgUrl}: VisitorCardProps) => {



	return (
		<SlipContainer backgroundColor={backgroundColor}>

			<SlipHeader>
				<h1>{parseExpiryDate(visitorIssuedTime, true)}</h1>
        <img src={logoImgUrl} alt="Logo" />
      </SlipHeader>

			<VisitorTitle>VISITOR</VisitorTitle>

			<VisitorDetails>
				<span>Entry Number: {visitorId}</span><br/>
				<span>Entry Date/Time: {parseExpiryDate(visitorIssuedTime)}</span><br/>
				<span>Name: {fullName}</span><br/>
			</VisitorDetails>

			<SignatureContainer>
				I declare that I am over 18 and if required will show identification
				<br/>
				{signatureImgUrl && (
					<>
						<span>Signature</span>
						<img src={signatureImgUrl} />
					</>
				)}
			</SignatureContainer>

			<TermsConditions>
				Conditions of entry: <br/>

				<br/>Please Read Carefully
				<br/>I declare I am over the age of 18 and if required will show identification, details of which will be recorded.
				<br/>I will adhere to the directions of Management of the Club, including the Responsible Service of Alcohol, Dress Regulations and Portray Responsible Behaviour and Sensible approach to Gambling.
				<br/>I am aware of the consequences of entering premises where smoking is permitted.
				<br/>I will produce this temporary member's card when requested.
				<br/><br/>
				<strong>
					BECOME A MEMBER TODAY<br/>
					FROM $7 A YEAR!!!
				</strong>
			</TermsConditions>
		</SlipContainer>
	)
}
export default VisitorSlip;
