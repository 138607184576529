import React, {createContext, Dispatch, SetStateAction, useContext, useState} from "react";

type CurrentMemberInfo = {
	userId?: string;
	playerAccNum?: string;
	cmsPlayerId?: string;
	cardQrCode?: string;
	expirationDate?: string;
	tierName?: string;
	currentLengthMonths?: number;
	currentPointsBalance?: number;
	isUserAMember?: boolean;
	eligibleForRenewal?: boolean;
	isVisitorEligible?: boolean;
}

type VisitorInfo = {
	visitorId?: number,
	fullName?: string,
	selfieImgUrl?: string,
	signatureImgUrl?: string,
	visitorIssuedTime?: string,
	backgroundColor?: string,
}

type MemberInfo = {
	familyName: string,
	givenName: string,
	birthdate: string,
	title: string,
	gender: string,
	number: string,
	email: string,
	// licenceAddress: string,
	address: string,
	// street: string,
	// suburb: string,
	// postcode: string,
	signature: string,
	// selfieBase64: string,
	// membershipDurationYears: number | "perpetual",
	memberNumber: string,
	currentMemberInfo?: CurrentMemberInfo,
	isPensionEligible?: boolean,
	userConfirmedPensioner?: boolean,
	isVisitor: boolean,
	visitorInfo?: VisitorInfo,
};


export type MembershipProduct = {
	venueMembershipProductId: number,
	stripeProductId: string,
	displayName: string,
	displayPrice: string,
	description: string,
	productType: "NEW_MEMBERSHIP" | "RENEW_MEMBERSHIP",
	skipPayment: boolean,
	isPensionerProduct?: boolean
}

type VenueTheme = {
	venueId: number,
	venueKey: string,
	venueState: string,
	displayName: string,
	homePageRichHtml?: string,
	homeShortRichHtml?: string,
	logoImgUrl: string,
	coverBackgroundImgUrl: string,
	headerColor: string,
	cardBackgroundImgUrl: string,
	// membershipProducts: MembershipProduct[],
	newProducts: MembershipProduct[],
	renewalProducts: MembershipProduct[],
	termsConditionsUrl: string,
	declarationRichHtml: string,
	tosRichHtml: string,
	marketingShowAnnualReport: boolean,
	marketingShowGaming: boolean,
	marketingShowGeneral: boolean,
	showAutoRenew: boolean,
	showOccupation: boolean,
	emailOptional: boolean,
	// showPensioner: boolean,
	removeCashPayment: boolean,
	showNameTitle: boolean,
	requireNameTitle: boolean,
	enableVisitorSignups: boolean,
}

type ApplicationState = {
	theme?: VenueTheme | null,
	flowToken?: string,
	nextFlowGuid?: string,
	// checkoutProductId?: string,
	venueMembershipProductId?: number,
}

const MembershipApplicationContext = createContext({
	userInfo: {} as Partial<MemberInfo>,
	setUserInfo: {} as Dispatch<SetStateAction<Partial<MemberInfo>>>,
	appState: {} as Partial<ApplicationState>,
	setAppState: {} as Dispatch<SetStateAction<Partial<ApplicationState>>>,
});

const defaultApp = {
	// nextFlowGuid: '8dbb6f75-3059-4818-b60a-1fe288e54213',
	// flowToken: '51fbd50fa35646d0b419e1a9b4fb4a0ed742c316ffde49bfbcf6aecfe5c8da0b0975d6'
}
const MembershipApplicationProvider = ({ children, user = {} as MemberInfo, app = defaultApp as ApplicationState }: {
	children: React.ReactNode;
	user?: Partial<MemberInfo>;
	app?: Partial<ApplicationState>;
}) => {
	const [userInfo, setUserInfo] = useState(user);
	const [appState, setAppState] = useState(app);
	return (
		<MembershipApplicationContext.Provider value={{ userInfo, setUserInfo, appState, setAppState }}>
			{children}
		</MembershipApplicationContext.Provider>
	);
};

const useMembershipApplication = () => {
	const context = useContext(MembershipApplicationContext);
	if (!context) {
		throw new Error("useMembershipApplication must be used within a MembershipApplicationContext");
	}

	// const {userInfo, setUserInfo} = context;
	// const updateData = (value: Partial<MembershipApplicationType>) => {
	// 	setUserInfo(value);
	// };
	// return { userInfo, setUserInfo: updateData };

	return context;
};

export { MembershipApplicationProvider, useMembershipApplication };
