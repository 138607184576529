import styled from "@emotion/styled";
import {keyframes} from "@emotion/react";
import React from "react";
import ApiClient from "rest/ApiClient";

type CardWrapperProps = {
	textColor?: string
	textShadow?: string
}
const CardWrapper = styled.div<CardWrapperProps>`
  --card-width: calc(var(--root-view-width) * 0.65);
	--card-height: calc(var(--card-width) / 1.7);
  width: var(--card-width);
  height: var(--card-height);
  //--card-width: 471px;
  //width: var(--card-width);
  //height: 277px;
  user-select: none;
  //aspect-ratio: 1.7;
	
  //font-size: 12px;
  //font-size: calc(10px + var(--root-view-width) * 0.01);
  font-size: calc(var(--card-width) * 0.054);
	position: relative;
	overflow: hidden;
	border-radius: 10px;
	//background: #00000096;
  box-shadow: #00000096 2px 2px 6px;
  ${props => `color: ${props.textColor ?? 'white'};`}
  ${props => `text-shadow: ${props.textShadow ?? 'black 0 0 4px'};`}

	text-align: left;
  font-family: Arial, sans-serif;
`;

const CardBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  box-sizing: border-box;
  //left: 0;
	//margin: 0;
	//padding: 0;
  //overflow: visible;
	//z-index: -1;
`;

type CardContentProps = {
	// paddingPercent?: number,
	paddingPercentHorizontal?: number,
	paddingPercentTop?: number,
	paddingPercentBottom?: number,
	imageSizePercent?: number
}

//--card-content-padding-v: calc(var(--card-width) * ${props => props.paddingPercentVertical ?? 0.06});
const CardContent = styled.div<CardContentProps>`
  --card-content-padding-h: calc(var(--card-width) * ${(props: CardContentProps) => props.paddingPercentHorizontal ?? 0.06});
  --card-content-padding-top: calc(var(--card-width) * ${props => props.paddingPercentTop ?? 0.06});
  --card-content-padding-bottom: calc(var(--card-width) * ${props => props.paddingPercentBottom ?? 0.06});
	--photo-size: calc(var(--card-width) * ${props => props.imageSizePercent ?? 0.28});
  --left-text-width: calc(var(--card-width) - (var(--card-content-padding-h) * 3) - var(--photo-size));
	--bottom-text-width: calc((var(--card-width) - (var(--card-content-padding-h) * 2)) / 2);
	//position: absolute;
	//top: 0;
	//left: 0;
	//right: 0;
  //bottom: 0;
	position: relative;
  box-sizing: border-box;
	width: 100%;
	height: 100%;
  padding: var(--card-content-padding-top) var(--card-content-padding-h) var(--card-content-padding-bottom) var(--card-content-padding-h);
`

type PhotoPosition = "TOP_LEFT" | "TOP_RIGHT" | "BOTTOM_RIGHT" | "CENTER_RIGHT"
type PhotoProps = {
	position?: PhotoPosition
}
const MemberPhoto = styled.img<PhotoProps>`
  width: var(--photo-size);
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;
  ${({position}) => {
    if (position === "TOP_LEFT") {
      return `top: var(--card-content-padding-top);
              left: var(--card-content-padding-h);`
    } else if (position === "BOTTOM_RIGHT") {
      return `bottom: var(--card-content-padding-bottom);
              right: var(--card-content-padding-h);`
    } else if (position === "CENTER_RIGHT") {
      return `top: calc((var(--card-height) - var(--photo-size)) / 2);
              right: var(--card-content-padding-h);`
    } else {
      return `top: var(--card-content-padding-top);
              right: var(--card-content-padding-h);`
    }
  }}
  border-radius: 4px;
`;
const VisitorPhoto = styled.img`
  width: var(--photo-size);
	aspect-ratio: 1;
	border-radius: 50%;
	object-fit: cover;
	position: absolute;
	top: var(--card-content-padding-top);
	right: var(--card-content-padding-h);
`;

type TextPosition = "LEFT" | "BOTTOM" | "TOP_RIGHT"
type TextProps = {
	position?: TextPosition
	minHeight?: number
	textLeftPadding?: string
	textGap?: any
}
const MemberDetails = styled.div<TextProps>`
	//height: 100%;
  //min-height: 25%;
  ${({minHeight}) => `min-height: ${minHeight ?? 25}%;`}
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  line-height: normal;
	//padding: 20px;
  box-sizing: border-box;
  ${({textGap}) => !!textGap ? `gap: ${textGap};` : undefined}
  ${({textLeftPadding}) => !!textLeftPadding ? `padding-left: ${textLeftPadding};` : undefined}

  ${({position}) => {
    if (position === "LEFT") {
      return `height: 100%;`
    } else {
      return `//height: 30%;
              width: calc(100% - var(--card-content-padding-h) * 2);
              position: absolute;
              bottom: var(--card-content-padding-bottom);`
    }
  }}
`;

const MemberName = styled.div<TextProps>`
  ${({position}) => 
    position === "LEFT" ? 'width: var(--left-text-width);' : 'width: 100%;'
  }
	font-weight: 700;
  line-height: normal;
	//font-size: 1em;
	//z-index: 1;
`;
const MemberNumberTitle = styled.div`
  width: var(--left-text-width);
  display: inline-block;
	font-size: 0.8em;
  font-weight: 400;
	opacity: 0.9;
`;
const MemberNumber = styled.div`
  width: var(--left-text-width);
  display: inline-block;
  line-height: 0.8em;
  font-weight: 300;
`;
const MembershipExpiryDate = styled.div<TextProps>`
  width: var(--bottom-text-width);
	align-self: end;
  font-size: 0.8em;
  text-align: right;
  //z-index: 1;
	
  ${({position}) => {
		if (position === "TOP_RIGHT") {
			return 'position: absolute; top: -200%; right: 0; font-size: 1em; font-weight: bold;'
		}
		return position === "BOTTOM" && 'position: absolute; bottom: 0; right: 0;'
  }}
`;
const memberCardLayout = (venueId: number)=> {

	// Defaults
	let textColour = 'white'
	let textShadow = 'black 0 0 4px' // 'none'
	let textPos:TextPosition = 'LEFT'
	let expiryPos:TextPosition = 'LEFT'
	let photoPos:PhotoPosition = 'TOP_RIGHT'
	// let paddingPercent = 0.06
	let paddingPercentHorizontal = 0.06
	let paddingPercentTop = 0.06
	let paddingPercentBottom = 0.06
	let imageSizePercent = 0.28
	let expiryText = 'Expires: '
	let showMemberNumberTitle = true
	let minHeight = 25
	let shortExpiryDate = false
	let textLeftPadding: any = 0
	let textGap: any = 0
	let memberNumberFontSize = '1em'

	if ([49, 78].includes(venueId)) {
		// moorebank, corowarsl
		textPos = expiryPos = 'BOTTOM'
		textColour = 'black'
		textShadow = 'white 0 0 4px' // 'none'
		paddingPercentHorizontal = 0.03
		paddingPercentTop = 0.03
		paddingPercentBottom = 0.03
		imageSizePercent = 0.25
	} else if ([163].includes(venueId)) {
		// carinaleagues
		photoPos = 'CENTER_RIGHT'
		textPos = expiryPos = 'BOTTOM'
		// textColour = 'black'
		// textShadow = 'white 0 0 4px' // 'none'
		paddingPercentHorizontal = 0.03
		paddingPercentTop = 0.03
		paddingPercentBottom = 0.03
		imageSizePercent = 0.25
	} else if ([79].includes(venueId)) {
		// cclc
		textPos = 'BOTTOM'
		expiryPos = 'TOP_RIGHT'
		textColour = 'black'
		textShadow = 'white 0 0 4px' // 'none'
		photoPos = 'BOTTOM_RIGHT'
		// paddingPercent = 0.05
		imageSizePercent = 0.25
		expiryText = 'Valid To '
		showMemberNumberTitle = false
	}	else if ([85].includes(venueId)) {
		// bankstownrsl
		textPos = 'BOTTOM'
		expiryPos = 'TOP_RIGHT'
		// textColour = 'black'
		// textShadow = 'white 0 0 4px' // 'none'
		photoPos = 'BOTTOM_RIGHT'
		paddingPercentHorizontal = 0.04
		paddingPercentTop = 0.04
		paddingPercentBottom = 0.04
		imageSizePercent = 0.25
		expiryText = 'Valid To '
		showMemberNumberTitle = false
	} else if ([165].includes(venueId)) {
		// maroubraseals
		textPos = expiryPos = 'BOTTOM'
		photoPos = 'CENTER_RIGHT'
		textColour = 'black'
		textShadow = 'white 0 0 4px' // 'none'
		paddingPercentHorizontal = 0.03
		paddingPercentTop = 0.007
		paddingPercentBottom = 0.007
		imageSizePercent = 0.25
		showMemberNumberTitle = false
		minHeight = 20
	}	else if ([41].includes(venueId)) {
		// batemansbay
		textPos = 'BOTTOM'
		expiryPos = 'TOP_RIGHT'
		textColour = 'black'
		textShadow = 'white 0 0 4px'
		photoPos = 'BOTTOM_RIGHT'
		paddingPercentHorizontal = 0.04
		paddingPercentTop = 0.04
		paddingPercentBottom = 0.04
		showMemberNumberTitle = false
		shortExpiryDate = true
	} else if([148].includes(venueId)) {
		// csisouthport
		textPos = expiryPos = 'BOTTOM'
		photoPos = 'CENTER_RIGHT'
		textColour = 'black'
		textShadow = 'rgba(255,255,255,0.5) 0 0 4px' // 'none'
		paddingPercentHorizontal = 0.03
		paddingPercentTop = 0.007
		paddingPercentBottom = 0.007
		imageSizePercent = 0.23
		showMemberNumberTitle = false
		minHeight = 20
	} else if ([37].includes(venueId)) {
		// caloundrarsl
		photoPos = 'TOP_LEFT'
		textPos = 'BOTTOM'
		expiryPos = 'LEFT'
		textColour = 'black'
		textShadow = 'rgba(255,255,255,0.5) 0 0 4px' // 'none'
		// paddingPercent = 0.05
		imageSizePercent = 0.25
		expiryText = 'Valid To '
		// showMemberNumberTitle = false
		textLeftPadding = '30%'
		textGap = 'calc(var(--card-content-padding-h) * 0.65)'
	} else if ([179].includes(venueId)) {
		// maroochyrsl
		// showMemberNumberTitle = false
		textPos = expiryPos = 'LEFT'
		textColour = 'black'
		textShadow = 'white 0 0 4px' // 'none'
		// paddingPercentHorizontal = 0.03
		paddingPercentTop = 0.04
		paddingPercentBottom = 0.2
		imageSizePercent = 0.25
		// minHeight = 20
	} else if ([184, 185, 186].includes(venueId)) {
		// weststamworth, westsdiggers, thecourts
		showMemberNumberTitle = false
		textPos = expiryPos = 'BOTTOM'
		textColour = 'black'
		textShadow = 'white 0 0 4px' // 'none'
		paddingPercentHorizontal = 0.03
		paddingPercentTop = 0.03
		paddingPercentBottom = 0.0
		imageSizePercent = 0.32
		minHeight = 20
		memberNumberFontSize = '0.85em'
	}
	return {
		textColour, textShadow, textPos, expiryPos, expiryText, minHeight, shortExpiryDate,
		showMemberNumberTitle, photoPos, paddingPercentHorizontal, paddingPercentTop, paddingPercentBottom,
		imageSizePercent, textLeftPadding, textGap, memberNumberFontSize
	}
}

export type MemberCardProps = {
	backgroundImage: string,
	memberPhoto: string,
	memberName: string,
	memberNumber: string,
	expiryDate: string
}

export const MemberCard = ( {backgroundImage, memberPhoto, memberName, memberNumber, expiryDate}: MemberCardProps) => {
	const cardLayout = memberCardLayout(ApiClient.venueId);

	return (
		<CardWrapper textColor={cardLayout.textColour} textShadow={cardLayout.textShadow}>
			<CardBackground src={backgroundImage} />
			<CardContent
				paddingPercentHorizontal={cardLayout.paddingPercentHorizontal}
				paddingPercentTop={cardLayout.paddingPercentTop}
				paddingPercentBottom={cardLayout.paddingPercentBottom}
				imageSizePercent={cardLayout.imageSizePercent}>
				<MemberDetails position={cardLayout.textPos} minHeight={cardLayout.minHeight} textLeftPadding={cardLayout.textLeftPadding} textGap={cardLayout.textGap}>
					<MemberName position={cardLayout.textPos}>{memberName}</MemberName>
					<div style={{zIndex: 1, fontSize: cardLayout.memberNumberFontSize}}>
						{cardLayout.showMemberNumberTitle && <MemberNumberTitle>Member Number</MemberNumberTitle>}
						{ cardLayout.shortExpiryDate ? (
							<MemberNumber style={{fontSize: '0.85em'}}>{memberNumber} - Exp {expiryDate}</MemberNumber>
						) : (
							<MemberNumber>{memberNumber}</MemberNumber>
						)}
					</div>
					{expiryDate !== "never" && !cardLayout.shortExpiryDate && (
						<MembershipExpiryDate position={cardLayout.expiryPos}>{cardLayout.expiryText}{expiryDate}</MembershipExpiryDate>
					)}
				</MemberDetails>

				<MemberPhoto src={memberPhoto} position={cardLayout.photoPos}/>
			</CardContent>
		</CardWrapper>
	)
}

export type VisitorCardProps = {
	backgroundImage: string,
	visitorId: number,
	fullName: string,
	selfieImgUrl: string,
	visitorIssuedTime: string,
	// cardQrCode: string,
	backgroundColor: string,
	logoImgUrl: string
}

export const VisitorCard = ({backgroundImage, fullName, selfieImgUrl, visitorIssuedTime, visitorId, backgroundColor, logoImgUrl}: VisitorCardProps) => {
	const cardLayout = memberCardLayout(ApiClient.venueId);

	return (
		<CardWrapper textColor={'black'} textShadow={'rgba(255,255,255,0.5) 0 0 4px'} style={{borderRadius: '1em', fontFamily: 'unset'}}>
			{/*<CardBackground src={backgroundImage} />*/}
			<div style={{background: backgroundColor, width: '100%', height: '100%', position: 'absolute'}} />
			<CardContent
				paddingPercentHorizontal={cardLayout.paddingPercentHorizontal}
				paddingPercentTop={cardLayout.paddingPercentTop}
				paddingPercentBottom={cardLayout.paddingPercentBottom}
				imageSizePercent={cardLayout.imageSizePercent}>
				<MemberDetails position={cardLayout.textPos} minHeight={cardLayout.minHeight} textLeftPadding={cardLayout.textLeftPadding} textGap={cardLayout.textGap}>
					<img src={logoImgUrl} style={{width: '20%', aspectRatio: 1, objectFit: 'contain'}} />
					<MemberName position={cardLayout.textPos}>{fullName}</MemberName>
						<div style={{fontSize: '0.85em', fontWeight: 500}}>TEMPORARY MEMBER PASS</div>

					<div style={{display: 'flex', width: '100%', fontSize: '0.85em', fontWeight: 600, justifyContent: 'space-between'}}>
						{visitorIssuedTime !== "never" && (
							<div>{visitorIssuedTime}</div>
						)}
						<div>Entry number: {visitorId}</div>
					</div>

				</MemberDetails>

				<VisitorPhoto src={selfieImgUrl}/>
			</CardContent>
		</CardWrapper>
	)
}


const shimmer = keyframes`
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 0 50%;
  }
`
const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ShimmerCard = styled.div`
  user-select: none;
  width: 70%;
  aspect-ratio: 1.7;
	padding: 20px;
  border-radius: 10px;
  box-shadow: #00000044 2px 2px 10px;
  //position: relative;
  //overflow: hidden;
	display: flex;
	flex-direction: column;

  //background-color: #f6f7f8;
  background: linear-gradient(-45deg, rgb(238, 238, 238) 8%, rgb(222, 222, 222) 18%, rgb(238, 238, 238) 33%);
  background-size: 1500% 100%;
  animation: ${shimmer} 2s ease-in-out infinite, ${fadein} 0.2s forwards;
`;

type LineProps = {
	length: string
}
const Line = styled.div<LineProps>`
  height: 12px;
	border-radius: 6px;
	width: ${props => props.length};
  background: rgba(0,0,0,0.05);
`;

interface PlaceholderCardProps {
	style?: React.CSSProperties | undefined
}
export const PlaceholderCard = ({ style } : PlaceholderCardProps) => {
	return (
		<ShimmerCard style={style}>
			{/* top */}
			<div style={{width: '100%', flex: '3', display: "flex"}} >
				{/* left */}
				<div style={{width: '100%', flex: '2.5', display: "flex", flexDirection: "column", gap: 8, paddingTop: 8}} >
					<Line length='75%' />
					<Line length='55%' />
					<Line length='55%' />
				</div>
				{/* right */}
				<div style={{width: '100%', flex: '1'}}>
					{/* profile image */}
					<div style={{background: "rgba(0,0,0,0.05)", width: '100%', aspectRatio: 1, borderRadius: 6}} />
				</div>
			</div>
			{/* bottom */}
			<div style={{width: '100%', flex: '1', display: 'flex', alignItems: "end", justifyContent: 'end'}} >
				<Line length='40%' />
			</div>

		</ShimmerCard>
	)
}

